import React, { Component } from 'react'
import './Aula.scss'
import './../../main/ultil.scss'
import Autocomplete from '@material-ui/lab/Autocomplete';
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import axios from 'axios'
import { Grid, Button, TextField } from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import ModalAlunosAulaFixa from './../../components/modals/AlunosAulaFixa'
import Alert from './../../components/templates/Alert'

const initalState = {
  aulasFixas: {
    list: []
  },
  horarios: [],
  aulaFixaSelecionada: {
    aula_id: '',
    aula_nome: ''
  },
  agendasSalvar: [],
  professores: {
    list: []
  },
  agendas: {
    list: []
  },
  datas: [],
  loading: true,
  alertHorario: false,
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  agendasUnidade: {
    list: []
  },
  aulas: [],
  aula: {
    aula_id: '',
    aula_nome: ''
  },
  rowProfessores: [],
  afastamentos: {
    list: []
  },
  disabledSalvar: false,
  openModalAlunosAulaFixa: false,
  agendasSelecionadas: [],
  alunosSelecionados: [],
  filtro: {
    aluno_matricula: "",
    pesf_nome: "",
    pesf_cpf: "",
    stcontmat_situacao: "APROVADO",
    filtrar_por: "Subscrição - aluno_matricula",
    value: '',
    limit: 10,
    offset: 0,
  },
  filtroAluno: {
    label: 'Subscrição',
    name:'aluno_matricula'
  },
  alunos: {
    list: []
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  configuracao: {
    confunin_id: '',
    confunin_intervalo_aula_fixa: 1
  },
  rows: 0
}

export default class AbrirAulaFixa extends Component {
  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async componentWillMount() {

    this.setState({
      rowProfessores: []
    })

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'abrir_aula_fixas')[0]

    this.setState({
      permissoes
    })

    if (!permissoes.perm_inserir) {
      this.setState({
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Você não tem permissão para acessa essa tela!'
        }
      })

      this.backPage()
    }

    try {

      const { data: aulasFixas } = await axios.get(`${process.env.REACT_APP_API_URL}/aula/aulasFixas`, this.getToken())

      let aulas = []
      aulasFixas.filter(param => param.aula_ativo === true).sort((a, b) => (a.aula_nome > b.aula_nome) ? 1 : ((b.aula_nome > a.aula_nome) ? -1 : 0)).map(aula => {
        aulas.push({
          aula_id: aula.aula_id,
          aula_nome: aula.aula_nome,
          aula_duracao: aula.aula_duracao
        })
        return false
      })

      this.setState({
        aulas
      })

    } catch ({response}) {
      // console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: response.data.message
        }
      })
     
      return
    }
    const datas = []

    var hoje = new Date();
    var ano = hoje.getFullYear();
    var mes = hoje.getMonth();
    var dia = hoje.getDate();

    const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

    const dados = {
      unidadesnegocio: acesso_atual.map(acesso => {
        return acesso.unin_id
      })
    }

    try {
      const { data: configuracao } = await axios.get(`${process.env.REACT_APP_API_URL}/configuracaoUnidade/byUnidade/${dados.unidadesnegocio[0]}`, this.getToken())

      for (var i = 0; i < (configuracao.confunin_intervalo_aula_fixa * 30); i++) {
        var outroDia = new Date(ano, mes, dia + i);
        datas.push({
          dataFormatada: moment(outroDia).format("DD/MM/YYYY"),
          dia: moment(outroDia).format("DD"),
          mes: moment(outroDia).format("MM"),
          ano: moment(outroDia).format("YYYY"),
          diaSemana: moment(outroDia).format("dddd").replace("-feira", "").toUpperCase(),
        })
      }
    } catch ({response}) {
      // console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: response.data.message
        }
      })
     
      return
    }


    try {

      let data_final = new Date();
      data_final = data_final.setDate(data_final.getDate() + 30)

      const periodo = {
        data_inicial: moment(hoje).format('YYYY-MM-DD'),
        data_final: moment(data_final).format('YYYY-MM-DD'),
      }

      const { data: agendas } = await axios.post(`${process.env.REACT_APP_API_URL}/agendaAula/byUnidade/periodo/${dados.unidadesnegocio[0]}`, periodo, this.getToken())
      const { data: afastamentos } = await axios.post(`${process.env.REACT_APP_API_URL}/afastamentoFuncionario/periodo/${dados.unidadesnegocio[0]}`, periodo, this.getToken())
     

      this.setState({
        agendasUnidade: {
          list: agendas
        },
        afastamentos: {
          list: afastamentos.filter(param => param.afafun_ativo)
        }
      })
    } catch ({response}) {
      // console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: response.data.message
        }
      })
     
      return
    }

    this.setState(
      {
        loading: false,
        datas
      }
    )

  }

  backPage(timeout = 1000) {
    const self = this

    setTimeout(() => {
      self.props.history.push("/aula");
    }, timeout)
  }

  async updateFieldAula(event, value) {
    if (!value) return false
    this.setState({
      loading: true
    })

    let { datas, aulaFixaSelecionada, aulas } = this.state

    aulaFixaSelecionada = aulas.filter(param => param.aula_id === parseInt(value.aula_id))[0]

    try {

      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.unin_id
        })
      }

      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/horarioAula/listByAula/${aulaFixaSelecionada.aula_id}/${dados.unidadesnegocio[0]}`, this.getToken())
      const { data: professores } = await axios.get(`${process.env.REACT_APP_API_URL}/professores/byUnidadeByAula/${dados.unidadesnegocio[0]}/${aulaFixaSelecionada.aula_id}`, this.getToken())
      const { data: resp } = await axios.post(`${process.env.REACT_APP_API_URL}/agendaAula/byUnidade/byAula/${dados.unidadesnegocio[0]}/${aulaFixaSelecionada.aula_id}`, {}, this.getToken())

      if (data.list.length === 0) {
        this.setState({
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Essa aula não possui horarios configurados.'
          }
        })
      }

      const horarios = []

      for (let i = 0; i < data.list.length; i++) {
        horarios.push({
          horaulfix_id: data.list[i].horaulfix_id,
          horaulfix_dia_semana: data.list[i].horaulfix_dia_semana,
          horaulfix_hora_inicial: data.list[i].horaulfix_hora_inicial,
          horaulfix_hora_final: data.list[i].horaulfix_hora_final,
          index: i + 1
        })
      }

      const agendasAux = []
      // console.log(resp.feriados)

      let i = 0
      datas.forEach(data => {
        const aux = horarios.filter(param => param.horaulfix_dia_semana === data.diaSemana)

        aux.forEach(horario => {
          let indexFeriado = resp.feriados.findIndex(param => {
            // console.log('Feridado: ' + param.fer_descricao)
            // console.log('Data Feriado: ' + moment(param.fer_data).add(4, 'h').format('DD/MM/YYYY'))
            // console.log('Data Linha: ' + data.dataFormatada)
            // console.log('------------------------------')
            if(moment(param.fer_data).add(4, 'h').format('DD/MM/YYYY') === data.dataFormatada) return true
            return false
          })

          const agenda = {
            ageaula_id: '',
            ageaula_data_hora: moment(`${data.ano}-${data.mes}-${data.dia} ${horario.horaulfix_hora_inicial}`).format(),
            ageaula_status: 'ABERTO',
            ageaula_professor_id: '',
            prof_nome: '',
            ageaula_aula_id: parseInt(aulaFixaSelecionada.aula_id),
            ageaula_unin_id: parseInt(dados.unidadesnegocio[0]),
            alunos: [],
            feriado: indexFeriado === -1 ? false : resp.feriados[indexFeriado],
            index: i
          }

          const indexAgenda = resp.agendas.findIndex(param => moment(param.ageaula_data_hora).format('DD') === data.dia
            && moment(param.ageaula_data_hora).format('MM') === data.mes
            && moment(param.ageaula_data_hora).format('YYYY') === data.ano
            && moment(param.ageaula_data_hora).format('HH:mm') === horario.horaulfix_hora_inicial)

          if (indexAgenda !== -1) {
            agenda.ageaula_id = resp.agendas[indexAgenda].ageaula_id
            agenda.ageaula_professor_id = resp.agendas[indexAgenda].ageaula_professor_id
            agenda.prof_nome = resp.agendas[indexAgenda].professor.funcionario.fisica.pesf_nome
            agenda.alunos = resp.agendas[indexAgenda].alunos
          }

          agendasAux.push(agenda)
          i++
        });
      })

      this.setState({
        horarios,
        professores: {
          list: professores
        },
        agendas: {
          list: resp.agendas
        },
        agendasSalvar: agendasAux,
        aulaFixaSelecionada,
        loading: false,
        aula: value
      })

    } catch ({response}) {
      // console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: response.data.message
        }
      })
     
      return
    }
  }

  updateFieldProfessor(event, index) {
    const { agendasSalvar, professores } = this.state

    const value = event.target.value

    if (value !== '') {
      agendasSalvar[index].ageaula_professor_id = parseInt(value)
      agendasSalvar[index].prof_nome = professores.list.filter(param => param.prof_id === parseInt(value))[0].funcionario.fisica.pesf_nome

      this.setState({ agendasSalvar })
    } else {
      if (agendasSalvar[index].alunos.length !== 0) {
        this.setState({
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Essa aula possui alunos, e não é possivel remover o professor!'
          }
        })
      } else {
        agendasSalvar[index].ageaula_professor_id = ''
        agendasSalvar[index].prof_nome = ''

        this.setState({ agendasSalvar })
      }
    }

    this.setState({
      rowProfessores: []
    })

  }

  listProfessorDisp(agenda) {
    this.setState({
      rowProfessores: []
    })
    
    const { professores, agendasSalvar, aulaFixaSelecionada, agendasUnidade, afastamentos } = this.state
    const diaSemana = moment(agenda.ageaula_data_hora).format("dddd").replace("-feira", "").toUpperCase()
    const hora = moment(agenda.ageaula_data_hora).format("HH:mm")
    const data = agenda.ageaula_data_hora

    let index = null

    const rows = []

    const aula = aulaFixaSelecionada



    for (let i = 0; i < professores.list.length; i++) {
      let horaForm = hora.replace(":", "")
      horaForm = parseInt(horaForm)
      let horaMais = hora.split(":")
      horaMais = parseInt(horaMais[0]) + 1
      horaMais = horaMais < 10 ? `0${horaMais}` : horaMais
      horaMais = horaMais + ':00'
      let horaMenos = hora.split(":")
      horaMenos = parseInt(horaMenos[0]) - 1
      horaMenos = horaMenos < 10 ? `0${horaMenos}` : horaMenos
      horaMenos = horaMenos + ':00'

      const dataHoje = new Date()

      let indexAgenda = agendasUnidade.list.findIndex(agendaUni => {       
        if (dataHoje.getTimezoneOffset() === 240) {
          let dataAgenda = moment(agendaUni.ageaula_data_hora, moment.defaultFormat).toDate()
          dataAgenda.setMinutes(dataAgenda.getMinutes() + 60)
          if (moment(dataAgenda).format('YYYY-MM-DD HH:mm') === `${moment(data).format("YYYY-MM-DD")} ${hora}` && agendaUni.professor.prof_id === professores.list[i].prof_id && agenda.ageaula_id !== agendaUni.ageaula_id) {
            return true
          } else {
            return false
          }
        } else {
          if (moment(agendaUni.ageaula_data_hora).format('YYYY-MM-DD HH:mm') === `${moment(data).format("YYYY-MM-DD")} ${hora}` && agendaUni.professor.prof_id === professores.list[i].prof_id && agenda.ageaula_id !== agendaUni.ageaula_id) {
            return true
          } else {
            return false
          }
        }
      })

      if (aula.aula_duracao === 2) {
        if (indexAgenda === -1) {
          const indexAgendaPosterior = agendasUnidade.list.findIndex(agendaUni => moment(agendaUni.ageaula_data_hora).format('YYYY-MM-DD HH:mm') === `${moment(data).format("YYYY-MM-DD")} ${horaMais}` && agendaUni.professor.prof_id === professores.list[i].prof_id && agenda.ageaula_id !== agendaUni.ageaula_id)

          if (indexAgendaPosterior !== -1) {
            indexAgenda = indexAgendaPosterior
          }
        
        }
      }
      
      if (indexAgenda === -1) {
        const indexAgendaAnterior = agendasUnidade.list.findIndex(agendaUni => moment(agendaUni.ageaula_data_hora).format('YYYY-MM-DD HH:mm') === `${moment(data).format("YYYY-MM-DD")} ${horaMenos}` && agendaUni.professor.prof_id === professores.list[i].prof_id && agenda.ageaula_id !== agendaUni.ageaula_id)
      
        if (indexAgendaAnterior !== -1) {
          if (agendasUnidade.list[indexAgendaAnterior].ageaula_duracao_aula === 2) {
            indexAgenda = indexAgendaAnterior
          }
        }
      
      }

      if (indexAgenda === -1) {
        if (aula.aula_duracao === 2) {
          indexAgenda = agendasSalvar.findIndex(agendaUni => {
            const dataHoje = new Date()
            if (dataHoje.getTimezoneOffset() === 240) {
              let dataAgenda = moment(agendaUni.ageaula_data_hora, moment.defaultFormat).toDate()
              dataAgenda.setMinutes(dataAgenda.getMinutes() + 60)
              if (moment(dataAgenda).format('YYYY-MM-DD HH:mm') === `${moment(data).format("YYYY-MM-DD")} ${horaMais}` && agendaUni.ageaula_professor_id === professores.list[i].prof_id) {
                return true
              } else {
                return false
              }
            } else {
              if (moment(agendaUni.ageaula_data_hora).format('YYYY-MM-DD HH:mm') === `${moment(data).format("YYYY-MM-DD")} ${horaMais}` && agendaUni.ageaula_professor_id === professores.list[i].prof_id) {
                return true
              } else {
                return false
              }
            }
          })

          if (indexAgenda === -1) {
            indexAgenda = agendasSalvar.findIndex(agendaUni => {
              const dataHoje = new Date()
              if (dataHoje.getTimezoneOffset() === 240) {
                let dataAgenda = moment(agendaUni.ageaula_data_hora, moment.defaultFormat).toDate()
                dataAgenda.setMinutes(dataAgenda.getMinutes() + 60)

                console.log(`${moment(data).format("YYYY-MM-DD")} ${horaMenos}`)
                if (moment(dataAgenda).format('YYYY-MM-DD HH:mm') === `${moment(data).format("YYYY-MM-DD")} ${horaMenos}` && agendaUni.ageaula_professor_id === professores.list[i].prof_id) {
                  return true
                } else {
                  return false
                }
              } else {
                if (moment(agendaUni.ageaula_data_hora).format('YYYY-MM-DD HH:mm') === `${moment(data).format("YYYY-MM-DD")} ${horaMenos}` && agendaUni.ageaula_professor_id === professores.list[i].prof_id) {
                  return true
                } else {
                  return false
                }
              }
            })
          }
        }
      }

      if (indexAgenda === -1) {
        
        index = professores.list[i].disponibilidades.findIndex(param => param.disp_dia_semana === diaSemana && param.disp_hora_inicial_form === horaForm)

        if (aula.aula_duracao === 2) {
          index = professores.list[i].disponibilidades.findIndex(param => param.disp_dia_semana === diaSemana && param.disp_hora_inicial_form === (horaForm + 100))
        }

        // if(professores.list[i].prof_funcionario_id === 4189){
        //   console.log('Agenda: ', agenda)
        //   console.log('AulaFixaSelecionada: ', aulaFixaSelecionada)
        //   console.log('index: ', index)
        // }

        if (index !== -1) {
          const indexAusencia = afastamentos.list.findIndex(param => {
            if (new Date(`${moment(data).format("YYYY-MM-DD")} ${hora}`).valueOf() <= new Date(param.afafun_data_final).valueOf() && new Date(`${moment(data).format("YYYY-MM-DD")} ${hora}`).valueOf() >= new Date(param.afafun_data_inicial).valueOf() && param.afafun_fun_id === professores.list[i].funcionario.fun_id) {
              return true
            }else{
              return false
            }
          })

          if (indexAusencia === -1) {
            if (agenda.ageaula_professor_id !== professores.list[i].prof_id) {
              
              if (!professores.list[i].disponibilidades[index].disp_data_vig_fim && !professores.list[i].disponibilidades[index].disp_data_vig_inicio) {
                rows.push(
                  <option key={professores.list[i].prof_id} value={professores.list[i].prof_id}> {professores.list[i].funcionario.fisica.pesf_nome} </option>
                )
              } else if (Number(new Date(professores.list[i].disponibilidades[index].disp_data_vig_fim + ' 23:59:00')) > Number(new Date(data)) 
                      && Number(new Date(professores.list[i].disponibilidades[index].disp_data_vig_inicio + ' 00:01:00')) < Number(new Date(data))) {
                rows.push(
                  <option key={professores.list[i].prof_id} value={professores.list[i].prof_id}> {professores.list[i].funcionario.fisica.pesf_nome} </option>
                )
              } else if (Number(new Date(professores.list[i].disponibilidades[index].disp_data_vig_fim + ' 23:59:00')) > Number(new Date(data)) 
                && !professores.list[i].disponibilidades[index].disp_data_vig_inicio) {
                rows.push(
                  <option key={professores.list[i].prof_id} value={professores.list[i].prof_id}> {professores.list[i].funcionario.fisica.pesf_nome} </option>
                )
              } else if (!professores.list[i].disponibilidades[index].disp_data_vig_fim
                     && Number(new Date(professores.list[i].disponibilidades[index].disp_data_vig_inicio + ' 00:01:00')) < Number(new Date(data))) {
                rows.push(
                  <option key={professores.list[i].prof_id} value={professores.list[i].prof_id}> {professores.list[i].funcionario.fisica.pesf_nome} </option>
                )
              }
            }
          }

        }
      }

    }

    this.setState({
      rowProfessores: rows
    })
  }

  limpaProfessor() {
    this.setState({
      rowProfessores: []
    })
  }

  renderDatas() {
    const { agendasSalvar, agendasSelecionadas } = this.state

    const linhas = []

    agendasSalvar.forEach(agenda => {
      linhas.push(
        <tr key={agenda.ageaula_data_hora}>
          <td style={{width: 100}}> <p className='pAulaFixa align-left'>{moment(agenda.ageaula_data_hora).format("dddd").replace("-feira", "").toUpperCase()}</p> </td>
          <td style={{width: 200}}> <p className='pAulaFixa'>{moment(agenda.ageaula_data_hora).format("DD/MM/YYYY HH:mm")}</p> </td>
          <td>
            <TextField
              id="standard-select-currency"
              select
              variant="outlined"
              className="input"
              fullWidth
              size="small"
              SelectProps={{
                native: true,
              }}
              name="prof_id"
              onChangeCapture={(e) => this.updateFieldProfessor(e, agenda.index)}
              onClick={e => this.listProfessorDisp(agenda)}
              onBlur={e => this.limpaProfessor(e)}
              InputLabelProps={{
                shrink: true
              }}
              disabled={agenda.feriado ? true : false}
            >
              {agenda.feriado &&
                <option value={''}> Feriado {agenda.feriado.fer_descricao}</option>
              }
              {!agenda.feriado &&
                <React.Fragment>
                  <option value={agenda.ageaula_professor_id === "" ? "" : agenda.ageaula_professor_id}> {agenda.ageaula_professor_id === "" ? "Sem Professor" : agenda.prof_nome} </option>
                  {agenda.ageaula_professor_id !== '' &&
                    <option value=''>Sem Professor</option>
                  }
                  {this.state.rowProfessores}
                </React.Fragment>
              }
            </TextField>
          </td>
          <td style={{width: 120}}>
            {(!agenda.feriado && agenda.ageaula_professor_id !== '') &&
              <React.Fragment>
                {agendasSelecionadas.findIndex(param => param.ageaula_id === agenda.ageaula_id) === -1 &&
                  <Tooltip title='Selecionar' aria-label="add">
                    <IconButton style={{marginTop: 10}} onClick={e => this.selecionarAgenda(agenda)} color="primary" aria-label="editar">
                      <RadioButtonUncheckedIcon />
                    </IconButton>
                  </Tooltip>
                }
                
                {agendasSelecionadas.findIndex(param => param.ageaula_id === agenda.ageaula_id) !== -1 &&
                  <Tooltip title='Desmarcar' aria-label="add">
                    <IconButton style={{marginTop: 10}} onClick={e => this.selecionarAgenda(agenda)} color="primary" aria-label="editar">
                      <CheckCircleOutlineIcon />
                    </IconButton>
                  </Tooltip>
                }
              </React.Fragment>
            }
          </td>
        </tr>
      )
    })

    return linhas
  }

  selecionarAgenda(agenda){
    let { agendasSelecionadas } = this.state

    let index = agendasSelecionadas.findIndex(param => param.ageaula_id === agenda.ageaula_id)

    if(index === -1){
      agendasSelecionadas.push({
        ...agenda
      })
    }else{
      agendasSelecionadas.splice(index, 1)
    }

    this.setState({
      agendasSelecionadas
    })
  }

  async salvar(reload) {
    const { agendasSalvar } = this.state

    this.setState({
      disabledSalvar: true
    })

    if (agendasSalvar.length > 0) {

      this.setState({
        alerta: {
          open: true,
          severity: 'info',
          message: 'Salvando agendas...'
        }
      })

      try {

        const agendas = []

        agendasSalvar.forEach(async agenda => {
          const dadosAux = {
            ageaula_id: agenda.ageaula_id,
            ageaula_data_hora: agenda.ageaula_data_hora,
            ageaula_status: agenda.ageaula_status,
            ageaula_professor_id: agenda.ageaula_professor_id,
            ageaula_aula_id: agenda.ageaula_aula_id,
            ageaula_unin_id: agenda.ageaula_unin_id,
            alunos: agenda.alunos.map(aluno => {
              return{
                aluno_id: aluno.aluno_id
              }
            })
          }

          if (dadosAux.ageaula_id === '') {
            delete dadosAux.ageaula_id
          }

          if (dadosAux.ageaula_professor_id === '') {
            delete dadosAux.ageaula_professor_id
          }

          agendas.push(dadosAux)
        })

        const dados = {
          agendas
        }

        await axios.post(`${process.env.REACT_APP_API_URL}/agendaAula/createAulaFixa`, dados, this.getToken())

        if(reload){
          this.setState({
            alerta: {
              open: true,
              severity: 'success',
              message: 'Cadastro realizado com sucesso.'
            },
            disabledSalvar: false
          })

          window.location.reload()
        }else{
          this.updateFieldAula({},{aula_id: this.state.aulaFixaSelecionada.aula_id})

          this.setState({
            alerta: {
              open: true,
              severity: 'success',
              message: 'Cadastro realizado com sucesso.'
            },
            disabledSalvar: false
          })
        }

        this.handleCloseModalAlunosAulaFixa()
        
      } catch ({response}) {
        // console.log(error)
        this.setState({
          alerta: {
            open: true,
            severity: 'error',
            message: response.data.message
          },
          disabledSalvar: false
        })
       
        return
      }

    } else {
      this.setState({
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Por favor selecionar a aula fixa.'
        }
      })
      return
    }
  }

  informarAlunos(){
    let { agendasSelecionadas, alunosSelecionados } = this.state

    let alunosAux = []

    for (let i = 0; i < agendasSelecionadas.length; i++) {
      const agenda = agendasSelecionadas[i];
      
      
      for (const aluno of agenda.alunos) {
        // console.log(aluno)
        let index = alunosAux.findIndex(param => param._id === aluno.aluno_id)
  
        if(index === -1){
          alunosAux.push({
            _id: aluno.aluno_id,
            aluno_matricula: aluno.aluno_matricula,
            nome: aluno.fisica.pesf_nome + `${aluno.fisica.pesf_sobrenome ? ` ${aluno.fisica.pesf_sobrenome}` : ''}`,
            aluno_agenda_id: aluno.AlunoAgenda.aluaula_id
          })
        }
      }

      if(i === 0){
        alunosSelecionados = alunosAux
      }else{
        alunosSelecionados = alunosSelecionados.filter(item => alunosAux.some(item2 => item2._id === item._id))
      }

      alunosAux = []
    }

    this.setState({
      openModalAlunosAulaFixa: true,
      alunosSelecionados
    })
  }

  handleCloseModalAlunosAulaFixa(){
    this.setState({
      openModalAlunosAulaFixa: false,
      agendasSelecionadas: [],
      alunosSelecionados: [],
      filtro: {
        aluno_matricula: "",
        pesf_nome: "",
        pesf_cpf: "",
        stcontmat_situacao: "APROVADO",
        filtrar_por: "Subscrição - aluno_matricula",
        value: '',
        limit: 10,
        offset: 0,
      },
      filtroAluno: {
        label: 'Subscrição',
        name:'aluno_matricula'
      },
      alunos: {
        list: []
      },
    })
  }

  updateFiltro(event) {
    const filtro = this.state.filtro

    if (event.target.name === 'filtrar_por') {    
      this.setState({
        filtroAluno: {
          label: event.target.value.split(' - ')[0],
          name: event.target.value.split(' - ')[1]
        }
      })

      filtro[event.target.name] = event.target.value

      this.setState({
        filtro
      })
    }else{
      filtro[event.target.name] = event.target.value
      
      this.setState({
        filtro
      })
    }
  }

  async buscarAluno(filtro){
    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.unin_id
        })
      }

      let {filtroAluno} = this.state

      let filtro_aux = {
        aluno_matricula: "",
        pesf_nome: "",
        pesf_cpf: "",
        stcontmat_situacao: 'APROVADO',
        filtrar_por: filtro.filtrar_por,
        value: filtro.value,
        limit: filtro.limit,
        offset: filtro.offset,
      }

      filtro_aux[filtroAluno.name] = filtro.value ? filtro.value : ''

      filtro_aux.unidadesnegocio = dados.unidadesnegocio

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/aluno/list/filtro`, filtro_aux, this.getToken())

      this.setState({
        alunos: {
          list: data.list.map(aluno => {
            return {
              _id: aluno.aluno_id,
              aluno_matricula_ordem: parseInt((aluno.aluno_matricula ? aluno.aluno_matricula : '.').replace('.','')),
              aluno_matricula: aluno.aluno_matricula ? aluno.aluno_matricula : '',
              pesf_nome: `${aluno.pesf_nome}${aluno.pesf_sobrenome ? ` ${aluno.pesf_sobrenome}` : ''}`
            }
          })
        },
        rows: data.rows
      })
    } catch ({response}) {
      // console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: response.data.message
        }
      })
     
      return
    }
  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  async selecionarAluno(aluno){
    let {alunosSelecionados} = this.state
    
    let index = alunosSelecionados.findIndex(param => param._id === aluno._id)
   
    if(index === -1){
      alunosSelecionados.push({
        _id: aluno._id,
        aluno_matricula: aluno.aluno_matricula,
        nome: aluno.nome,
        aluno_agenda_id: null
      })
    }

    this.setState({
      alunosSelecionados
    })
  }

  async confirmarSelecaoAluno(value){
    if(value){
      this.setState({
        disabledSalvar: true
      })

      let { agendasSelecionadas, alunosSelecionados, agendasSalvar } = this.state

      for (let i = 0; i < agendasSelecionadas.length; i++) {
        for (const aluno of alunosSelecionados) {
          let index = agendasSelecionadas[i].alunos.findIndex(param => param.aluno_id === aluno._id)

          if(index === -1){
            agendasSelecionadas[i].alunos.push({
              aluno_id: aluno._id,
              aluno_matricula: aluno.aluno_matricula,
              fisica: {
                pesf_nome: aluno.nome
              }
            })
          }
        }
      }

      for (const agenda of agendasSelecionadas) {
        let index = agendasSalvar.findIndex(param => param.ageaula_id === agenda.ageaula_id)

        if(index !== -1){
          agendasSalvar[index].alunos = agenda.alunos
        }
      }

      this.setState({
        agendasSalvar
      })

      await this.salvar(false)
    }else{
      this.handleCloseModalAlunosAulaFixa()
    }
  }

  removerAluno(row){
    let { alunosSelecionados } = this.state
    let index = alunosSelecionados.findIndex(param => param._id === row._id)

    if(index !== -1){
      alunosSelecionados.splice(index, 1)
    }

    this.setState({alunosSelecionados})
  }

  async handleChangePage(event, newPage){
    const {filtro} = this.state

    filtro.offset = newPage

    this.setState({
      filtro
    })

    await this.buscarAluno(filtro)
  }

  async handleChangeRowsPerPage(event){
    const {filtro} = this.state

    filtro.limit = parseInt(event.target.value)

    this.setState({
      filtro
    })

    await this.buscarAluno(filtro)
  }

  render() {
    const { aula, aulas, disabledSalvar } = this.state
    const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

    const dados = {
      unidadesnegocio: acesso_atual.map(acesso => {
        return acesso.unin_id
      })
    }

    const qtdUnidade = dados.unidadesnegocio.length

    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {qtdUnidade > 1 &&
            <div>
              <h3>Por favor selecionar apenas uma unidade!</h3>
            </div>
          }
          {qtdUnidade === 1 &&
            <React.Fragment>
              {this.state.loading &&
                <React.Fragment>
                  <div className="progressCircular">
                    <CircularProgress />
                  </div>
                  <div className="progressText">
                    <p>Carregando Aulas Fixas...</p>
                  </div>
                </React.Fragment>
              }
              {!this.state.loading &&
                <div>
                  <Grid container spacing={1} direction="row" className="borderBottom">
                    <Grid item md={12}>
                      <h2 className="titulo">Abrir Aula Fixa</h2>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={1} className="mg_top_20">
                    <Grid item md={4} xs={12} sm={4}>
                      <p className='align-right mg-top-7'>Escolha a aula que deseja gerenciar: </p>
                    </Grid>
                    <Grid item md={8} xs={12} sm={8}>
                      <Autocomplete
                        onChange={(event, value) => this.updateFieldAula(event, value)}
                        freeSolo
                        options={aulas}
                        value={aula}
                        getOptionLabel={option => option.aula_nome}
                        renderInput={(params) => (
                          <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} label="Aula*" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={aula.aula_nome} />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={1} className="mg_top_20">
                    <Grid item md={12} xs={12} sm={12}>
                      {this.state.horarios.length > 0 &&
                        <table className="lista sempadding">
                          <thead>
                            <tr className="titulos acompanha">
                              <th>Dia</th>
                              <th>Data - Hora</th>
                              <th>Professor</th>
                              <th>Selecionar</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.renderDatas()}
                          </tbody>
                        </table>
                      }
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} direction="row" alignItems="flex-end" className="mg_top_10">
                    <Grid item md={6}></Grid>
                    <Grid item md={3}>
                      <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" onClick={e => this.informarAlunos(e)} disabled={disabledSalvar}>Informar Alunos</Button>
                    </Grid>
                    <Grid item md={3}>
                      <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<SaveIcon />} onClick={e => this.salvar(true)} disabled={disabledSalvar}>Salvar</Button>
                    </Grid>
                  </Grid>
                </div>
              }
            </React.Fragment>
          }
        </Main>
        <Nav />
        <Footer history={this.props.history} />

        <ModalAlunosAulaFixa
          open={this.state.openModalAlunosAulaFixa}
          handleClose={e => this.handleCloseModalAlunosAulaFixa(e)} 
          alunosSelecionados={this.state.alunosSelecionados}
          aula={this.state.aula}
          filtro={this.state.filtro}
          filtroAluno={this.state.filtroAluno}
          updateFiltro={(e) => this.updateFiltro(e)}
          buscarAluno={() => this.buscarAluno(this.state.filtro)}
          alunos={this.state.alunos.list}
          selecionarAluno={(e) => this.selecionarAluno(e)}
          confirmar={(e) => this.confirmarSelecaoAluno(e)}
          disabledSalvar={this.state.disabledSalvar}
          removerAluno={(e) => this.removerAluno(e)}
          rows={this.state.rows}
          handleChangePage={(event, newPage) => this.handleChangePage(event, newPage)}
          handleChangeRowsPerPage={e => this.handleChangeRowsPerPage(e)}
        />

        <Alert 
          open={this.state.alerta.open}
          handleClose={e => this.handleCloseAlerta()} 
          severity={this.state.alerta.severity}
          message={this.state.alerta.message} 
        />  
      </div>
    )
  }
}

