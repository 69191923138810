import React, { Component } from 'react'
import './../Aluno.scss'
import './../../../main/ultil.scss'
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'
import { Grid, Button, TextField } from '@material-ui/core'
import moment from 'moment';
import TableHistorico from '../../../components/TableHistorico'
import ModalUpdateHistorico from './../../../components/modals/UpdateHistorico'
import ModalErro from './../../../components/modals/Erro'
import { saveAs } from 'file-saver';
import { withStyles } from "@material-ui/core/styles";
import Color from 'color';

const initalState = {
  historicos: {
    list: []
  },
  historico: {
    carga_horaria_total: ""
  },
  historicosPorUnidade: {
    list: []
  },
  historicosPorTipoAula: {
    list: []
  },
  cabecalhoTabelaHistorico: [],
  acoesTabelaHistorico: [],
  cabecalhoTabelaHistoricoPorUnidade: [
    { id: 'aula_nome', numeric: false, disablePadding: false, label: 'Tipo da Aula' },
    { id: 'qtd', numeric: false, disablePadding: false, label: 'Aulas' },
    { id: 'total_duracao_aula', numeric: false, disablePadding: false, label: '	Carga horária' }
  ],
  acoesTabelaHistoricoPorUnidade: [],
  cabecalhoTabelaHistoricoPorTipoAula: [
    { id: 'aula_tipo', numeric: false, disablePadding: false, label: 'Tipo da Aula' },
    { id: 'qtd', numeric: false, disablePadding: false, label: 'Aulas' },
    { id: 'total_duracao_aula', numeric: false, disablePadding: false, label: '	Carga horária' }
  ],
  acoesTabelaHistoricoPorTipoAula: [],
  cabecalhoTabelaHistoricoEad:  [
    { id: 'curso', numeric: false, disablePadding: false, label: 'Curso' },
    { id: 'book', numeric: false, disablePadding: false, label: 'Book' },
    { id: 'aula', numeric: false, disablePadding: false, label: 'Aula' },
    { id: 'duracao', numeric: false, disablePadding: false, label: 'Duração' },
    { id: 'horaAula', numeric: false, disablePadding: false, label: 'Hora Aula' }
  ],
  filtroHistorico: {
    dataInicial: moment().subtract(30, 'days').format('YYYY-MM-DD'),
    dataFinal: moment().format('YYYY-MM-DD'),
    aluno_matricula: ""
  },
  loading: true,
  aluno: {},
  permissoesAlterarHistorico:{
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  permissoesImprimirHistorico:{
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  modalUpdateHistorico: false,
  historicoSelecionado:{
    agenda: {
      ageaula_data_hora: '',
      aula: {
        aula_nome: ''
      },
      professor:{
        funcionario:{
          fisica:{
            pesf_nome: ''
          }
        }
      }
    },
    historicos: []
  },
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  loadingTela: false,
  historicoEad: []
}

const converter = (minutos) => {
  const horas = Math.floor(minutos/ 60);          
  const min = minutos % 60;
  return `${horas }Hrs ${min}Min`;
};

function somarValores(array) {
  var soma = 0;
  for (var i = 0; i < array.length; i++) {
    soma += array[i];
  }
  return soma
}

const ColorYellowButton = withStyles((theme) => ({
  root: {
    color: '#000',
    backgroundColor: Color('#FFFF00').alpha(0.9).string(),
    '&:hover': {
      backgroundColor: '#FFFF00',
    },
  },
}))(Button);

export default class Agenda extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const aluno = this.props.aluno

    this.setState({
      loading: true,
      aluno
    })

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoesAlterarHistorico = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'alterar-nota-aluno')[0]
    let permissoesImprimirHistorico = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'imprimir-historico-aluno')[0]
  
    if(permissoesImprimirHistorico){
      this.setState({
        permissoesImprimirHistorico
      })
    }

    if(permissoesAlterarHistorico){

      if(permissoesAlterarHistorico.perm_alterar || permissoesAlterarHistorico.perm_inserir){
        this.setState({
          acoesTabelaHistorico: ['editarHistorico']
        })
      }

      this.setState({
        permissoesAlterarHistorico
      })
    }

    try {
      let filtroHistorico = this.state.filtroHistorico

      filtroHistorico.pesf_id = aluno.pessoaFisica.pesf_id
      filtroHistorico.curso_id = aluno.contrato.contm_curso_id

      const { data: dataAux } = await axios.post(`${process.env.REACT_APP_API_URL}/aluno/historico`, this.state.filtroHistorico, this.getToken())
      

      const cabecalhoTabelaHistorico = [
        { id: 'ageaula_data_hora', numeric: false, disablePadding: false, label: 'Quando' },
        { id: 'professor', numeric: false, disablePadding: false, label: 'Professor' },
        { id: 'aula_nome', numeric: false, disablePadding: false, label: 'Aula' },
        { id: 'aluaula_status', numeric: false, disablePadding: false, label: 'Situação' },
      ]

      const avaliacoesMatriz = []
      for (let i = 0; i < dataAux.historicoAulas.length; i++) {
        const avaliacoes = dataAux.historicoAulas[i].agenda.aula.avaliacoes.sort((a, b) => (a.avalaula_id > b.avalaula_id) ? 1 : ((b.avalaula_id > a.avalaula_id) ? -1 : 0))

        for (let j = 0; j < avaliacoes.length; j++) {
          const index = avaliacoesMatriz.findIndex(param => param.descricao === avaliacoes[j].avalaula_descricao)

          if (index === -1) {
            avaliacoesMatriz.push({
              id: avaliacoesMatriz.length + 1,
              abrev: avaliacoes[j].avalaula_descricao.substr(0, 1).toUpperCase(),
              descricao: avaliacoes[j].avalaula_descricao
            })

            cabecalhoTabelaHistorico.push({ id: avaliacoes[j].avalaula_descricao, numeric: true, disablePadding: false, label: avaliacoes[j].avalaula_descricao.substr(0, 1).toUpperCase() })
          }
        }
      }

      cabecalhoTabelaHistorico.push({ id: 'nota_total', numeric: true, disablePadding: false, label: 'Nota Total' })

      this.setState({
        cabecalhoTabelaHistorico
      })

      let historicosAux = []
      let historicosPorUnidadeAux = []
      let historicosPorTipoAulaAux = []

      dataAux.historicoAulas.forEach(historico => {
        const dados = {
          _id: historico.aluaula_id,
          aluaula_observacao: historico.aluaula_observacao,
          aluaula_status: historico.aluaula_status,
          ageaula_data_hora: moment(historico.agenda.ageaula_data_hora).format('DD/MM/YYYY HH:mm'),
          aula_nome: historico.agenda.aula.aula_nome,
          professor: historico.agenda.professor.funcionario.fisica.pesf_nome.split(' ')[0],
          ageaula_status: historico.agenda.ageaula_status,
          nota_total: somarValores(historico.historicos.map(historico => { return historico.hisaluno_nota_aval }))
        }

        historico.historicos.forEach(hist => {
          const index = avaliacoesMatriz.findIndex(param => param.descricao === hist.avaliacao.avalaula_descricao)

          dados[avaliacoesMatriz[index].descricao] = hist.hisaluno_nota_aval === '0' ? '-' : hist.hisaluno_nota_aval

        })

        historicosAux.push(dados)
      });

      for (let i = 0; i < dataAux.historicoPorUnidade.length; i++) {
        historicosPorUnidadeAux.push({
          _id: i + 1,
          aula_nome: dataAux.historicoPorUnidade[i].aula_nome,
          qtd: dataAux.historicoPorUnidade[i].qtd,
          total_duracao_aula: dataAux.historicoPorUnidade[i].total_duracao_aula + 'h',
        })
      }

      for (let i = 0; i < dataAux.historicoPorTipoAula.length; i++) {
        if (dataAux.historicoPorTipoAula[i].aula_tipo === 'UNIDADE') {
          historicosPorTipoAulaAux.push({
            _id: i + 1,
            aula_tipo: 'Aulas de Unidade',
            qtd: dataAux.historicoPorTipoAula[i].qtd,
            total_duracao_aula: dataAux.historicoPorTipoAula[i].total_duracao_aula + 'h',
            total: false
          })
        }
        if (dataAux.historicoPorTipoAula[i].aula_tipo === 'COMPLEMENTAR') {
          historicosPorTipoAulaAux.push({
            _id: i + 1,
            aula_tipo: 'Aulas Complementares',
            qtd: dataAux.historicoPorTipoAula[i].qtd,
            total_duracao_aula: dataAux.historicoPorTipoAula[i].total_duracao_aula + 'h',
            total: false
          })
        }
        if (dataAux.historicoPorTipoAula[i].aula_tipo === 'EVENTO') {
          historicosPorTipoAulaAux.push({
            _id: i + 1,
            aula_tipo: 'Eventos',
            qtd: dataAux.historicoPorTipoAula[i].qtd,
            total_duracao_aula: dataAux.historicoPorTipoAula[i].total_duracao_aula + 'h',
            total: false
          })
        }
        if (dataAux.historicoPorTipoAula[i].aula_tipo === 'PROVA') {
          historicosPorTipoAulaAux.push({
            _id: i + 1,
            aula_tipo: 'Prova',
            qtd: dataAux.historicoPorTipoAula[i].qtd,
            total_duracao_aula: dataAux.historicoPorTipoAula[i].total_duracao_aula + 'h',
            total: false
          })
        }
        if (dataAux.historicoPorTipoAula[i].aula_tipo === 'REVIEW') {
          historicosPorTipoAulaAux.push({
            _id: i + 1,
            aula_tipo: 'Review',
            qtd: dataAux.historicoPorTipoAula[i].qtd,
            total_duracao_aula: dataAux.historicoPorTipoAula[i].total_duracao_aula + 'h',
            total: false
          })
        }
      }

      historicosPorTipoAulaAux.push({
        _id: historicosPorTipoAulaAux.length + 1,
        aula_tipo: 'Total de aulas complementares + eventos',
        qtd: somarValores(dataAux.historicoPorTipoAula.map(param => {
          if (param.aula_tipo === 'COMPLEMENTAR' || param.aula_tipo === 'EVENTO') {
            return parseInt(param.qtd)
          } else {
            return 0
          }
        })),
        total_duracao_aula: somarValores(dataAux.historicoPorTipoAula.map(param => {
          if (param.aula_tipo === 'COMPLEMENTAR' || param.aula_tipo === 'EVENTO') {
            return parseInt(param.total_duracao_aula)
          } else {
            return 0
          }
        })) + 'h',
        total: true
      })

      historicosPorTipoAulaAux.push({
        _id: historicosPorTipoAulaAux.length + 1,
        aula_tipo: 'Total Geral',
        qtd: somarValores(dataAux.historicoPorTipoAula.map(param => { return parseInt(param.qtd) })),
        total_duracao_aula: somarValores(dataAux.historicoPorTipoAula.map(param => { return parseInt(param.total_duracao_aula) })) + 'h',
        total: true
      })

      this.setState({
        historicos: {
          list: historicosAux
        },
        historico: {
          carga_horaria_total: somarValores(dataAux.historicoPorTipoAula.map(param => { return parseInt(param.total_duracao_aula) })) + 'h',
        },
        historicosPorUnidade: {
          list: historicosPorUnidadeAux
        },
        historicosPorTipoAula: {
          list: historicosPorTipoAulaAux
        },
        loading: false
      })
    } catch ({ response }) {
      this.setState({
        loading: false
      })

      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }

    try {
      const { data: dataEad } = await axios.post(`${process.env.REACT_APP_API_URL}/aluno/historicoEad`, {aluno}, this.getToken())

      if(dataEad.sucesso){

        let historicoEad = []
        let total = 0
        let total_duracao = 0

        for (const value of dataEad.resultado) {
          historicoEad.push({
            curso: value.curso,
            book: value.book,
            aula: value.aula,
            duracao: converter(value.duracao),
            horaAula: converter(value.horaAula),
            total: false
          })

          total_duracao += value.duracao
          total += value.horaAula
        }

        historicoEad.push({
          curso: '',
          book: '',
          aula: 'Total',
          duracao: converter(total_duracao),
          horaAula: converter(total),
          total: true
        })

        this.setState({
          historicoEad
        })
      }     
    } catch ({ response }) {
      console.log(response.data.message)
    }

  }

  updateFiltroHistorico(event) {
    const filtroHistorico = { ...this.state.filtroHistorico }
    filtroHistorico[event.target.name] = event.target.value
    this.setState({ filtroHistorico })
  }

  async filtrarHistorico() {
    const aluno = this.props.aluno
    let filtroHistorico = this.state.filtroHistorico

    filtroHistorico.pesf_id = aluno.pessoaFisica.pesf_id
    filtroHistorico.curso_id = aluno.contrato.contm_curso_id

    this.setState({
      loading: true
    })

    try {
      const { data: dataAux } = await axios.post(`${process.env.REACT_APP_API_URL}/aluno/historico`, this.state.filtroHistorico, this.getToken())

      const cabecalhoTabelaHistorico = [
        { id: 'ageaula_data_hora', numeric: false, disablePadding: false, label: 'Quando' },
        { id: 'professor', numeric: false, disablePadding: false, label: 'Professor' },
        { id: 'aula_nome', numeric: false, disablePadding: false, label: 'Aula' },
        { id: 'aluaula_status', numeric: false, disablePadding: false, label: 'Situação' },
      ]

      const avaliacoesMatriz = []

      for (let i = 0; i < dataAux.historicoAulas.length; i++) {
        const avaliacoes = dataAux.historicoAulas[i].agenda.aula.avaliacoes

        for (let j = 0; j < avaliacoes.length; j++) {
          const index = avaliacoesMatriz.findIndex(param => param.descricao === avaliacoes[j].avalaula_descricao)

          if (index === -1) {
            avaliacoesMatriz.push({
              id: avaliacoesMatriz.length + 1,
              abrev: avaliacoes[j].avalaula_descricao.substr(0, 1).toUpperCase(),
              descricao: avaliacoes[j].avalaula_descricao
            })

            cabecalhoTabelaHistorico.push({ id: avaliacoes[j].avalaula_descricao, numeric: true, disablePadding: false, label: avaliacoes[j].avalaula_descricao.substr(0, 1).toUpperCase() })
          }
        }
      }

      cabecalhoTabelaHistorico.push({ id: 'nota_total', numeric: true, disablePadding: false, label: 'Nota Total' })

      this.setState({
        cabecalhoTabelaHistorico
      })

      let historicosAux = []
      let historicosPorUnidadeAux = []
      let historicosPorTipoAulaAux = []

      dataAux.historicoAulas.forEach(historico => {
        const dados = {
          _id: historico.aluaula_id,
          aluaula_observacao: historico.aluaula_observacao,
          aluaula_status: historico.aluaula_status,
          ageaula_data_hora: moment(historico.agenda.ageaula_data_hora).format('DD/MM/YYYY HH:mm'),
          aula_nome: historico.agenda.aula.aula_nome,
          professor: historico.agenda.professor.funcionario.fisica.pesf_nome.split(' ')[0],
          ageaula_status: historico.agenda.ageaula_status,
          nota_total: somarValores(historico.historicos.map(historico => { return historico.hisaluno_nota_aval }))
        }

        historico.historicos.forEach(hist => {
          const index = avaliacoesMatriz.findIndex(param => param.descricao === hist.avaliacao.avalaula_descricao)

          dados[avaliacoesMatriz[index].descricao] = hist.hisaluno_nota_aval === '0' ? '-' : hist.hisaluno_nota_aval

        })

        historicosAux.push(dados)
      });

      for (let i = 0; i < dataAux.historicoPorUnidade.length; i++) {
        historicosPorUnidadeAux.push({
          _id: i + 1,
          aula_nome: dataAux.historicoPorUnidade[i].aula_nome,
          qtd: dataAux.historicoPorUnidade[i].qtd,
          total_duracao_aula: dataAux.historicoPorUnidade[i].total_duracao_aula + 'h',
        })
      }

      for (let i = 0; i < dataAux.historicoPorTipoAula.length; i++) {
        if (dataAux.historicoPorTipoAula[i].aula_tipo === 'UNIDADE') {
          historicosPorTipoAulaAux.push({
            _id: i + 1,
            aula_tipo: 'Aulas de Unidade',
            qtd: dataAux.historicoPorTipoAula[i].qtd,
            total_duracao_aula: dataAux.historicoPorTipoAula[i].total_duracao_aula + 'h',
            total: false
          })
        }
        if (dataAux.historicoPorTipoAula[i].aula_tipo === 'COMPLEMENTAR') {
          historicosPorTipoAulaAux.push({
            _id: i + 1,
            aula_tipo: 'Aulas Complementares',
            qtd: dataAux.historicoPorTipoAula[i].qtd,
            total_duracao_aula: dataAux.historicoPorTipoAula[i].total_duracao_aula + 'h',
            total: false
          })
        }
        if (dataAux.historicoPorTipoAula[i].aula_tipo === 'EVENTO') {
          historicosPorTipoAulaAux.push({
            _id: i + 1,
            aula_tipo: 'Eventos',
            qtd: dataAux.historicoPorTipoAula[i].qtd,
            total_duracao_aula: dataAux.historicoPorTipoAula[i].total_duracao_aula + 'h',
            total: false
          })
        }
      }

      historicosPorTipoAulaAux.push({
        _id: historicosPorTipoAulaAux.length + 1,
        aula_tipo: 'Total de aulas complementares + eventos',
        qtd: somarValores(dataAux.historicoPorTipoAula.map(param => {
          if (param.aula_tipo === 'COMPLEMENTAR' || param.aula_tipo === 'EVENTO') {
            return parseInt(param.qtd)
          } else {
            return 0
          }
        })),
        total_duracao_aula: somarValores(dataAux.historicoPorTipoAula.map(param => {
          if (param.aula_tipo === 'COMPLEMENTAR' || param.aula_tipo === 'EVENTO') {
            return parseInt(param.total_duracao_aula)
          } else {
            return 0
          }
        })) + 'h',
        total: true
      });

      historicosPorTipoAulaAux.push({
        _id: historicosPorTipoAulaAux.length + 1,
        aula_tipo: 'Total Geral',
        qtd: somarValores(dataAux.historicoPorTipoAula.map(param => { return parseInt(param.qtd) })),
        total_duracao_aula: somarValores(dataAux.historicoPorTipoAula.map(param => { return parseInt(param.total_duracao_aula) })) + 'h',
        total: true
      });

      this.setState({
        historicos: {
          list: historicosAux
        },
        historico: {
          carga_horaria_total: 999,
        },
        historicosPorUnidade: {
          list: historicosPorUnidadeAux
        },
        historicosPorTipoAula: {
          list: historicosPorTipoAulaAux
        },
        loading: false
      })
    } catch (error) {
      this.setState({
        loading: false
      })
      console.log(error)
    }

  }

  async editar(historico) {
    try {
      
      let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/aluno/historico/show/${historico._id}`, this.getToken())
            
      data.presente = data.aluaula_status === 'PRESENTE' ? true : false
      
      this.setState({
        historicoSelecionado: data,
        modalUpdateHistorico: true
      })

    } catch (error) {
      console.log(error)
    }
  }

  handleCloseModalUpdateHistorico(){
    this.setState({
      modalUpdateHistorico: false
    })
  }

  updatePresenca(){
    const {historicoSelecionado} = this.state

    historicoSelecionado.presente = !historicoSelecionado.presente

    this.setState({
      historicoSelecionado
    })
  }

  updateNotaAvaliacao(event, hisaluno_id, avaliacao){
    const {historicoSelecionado} = this.state

    if(historicoSelecionado.agenda.aula.aula_id === 240 || historicoSelecionado.agenda.aula.aula_id === 5){
      for (let i = 0; i < historicoSelecionado.historicos.length; i++) {
        historicoSelecionado.historicos[i].hisaluno_nota_aval = 0
      }
    }

    const indexHistorico = historicoSelecionado.historicos.findIndex(param => param.hisaluno_id === hisaluno_id)
    
    historicoSelecionado.historicos[indexHistorico].hisaluno_nota_aval = event.target.value

    this.setState({
      historicoSelecionado
    })
    
  }

  async confirmarUpdateHistorico(value){
    if(value){
      const {historicoSelecionado} = this.state

      const historicos = historicoSelecionado.historicos

      for (const i in historicos) {
        if(parseInt(historicos[i].hisaluno_nota_aval) > historicos[i].avaliacao.avalaula_nota){
          this.setState({
            modalErro: true,
            erro: {
              titulo: 'Opps!',
              descricao: 'O valor da nota não pode ultrapassar o limite da avaliação.'
            }
          })
          return
        }

        if(historicos[i].hisaluno_nota_aval === ''){
          this.setState({
            modalErro: true,
            erro: {
              titulo: 'Opps!',
              descricao: 'Por favor informar todas as notas.'
            }
          })
          return
        }
      }

      try {
        const dados = {
          aluaula_id: historicoSelecionado.aluaula_id,
          aluaula_status: historicoSelecionado.presente ? 'PRESENTE' : 'AUSENTE',
          historicos: historicos.map(historico => {
            return {
              hisaluno_id: historico.hisaluno_id,
              hisaluno_nota_aval: parseInt(historico.hisaluno_nota_aval)
            }
          })
        }
        
        if(dados.historicos.length === 0){
          delete dados.historicos
        }

        await axios.put(`${process.env.REACT_APP_API_URL}/baixarAula/update/${historicoSelecionado.agenda.ageaula_id}`, dados, this.getToken())
     
        this.handleOpenDialog({
          titulo: 'Parabéns',
          descricao: 'Cadastro realizado com sucesso.'
        })

        window.location.reload()
      } catch ({ response }) {
        this.setState({
          loadingFiltro: false
        })
  
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        return
      }

    }else{
      this.handleCloseModalUpdateHistorico()
    }
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro ao Visualizar!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  async imprimir(){
    this.setState({loadingTela: true})

    const { filtroHistorico, cabecalhoTabelaHistorico, historicos, historicosPorUnidade, historicosPorTipoAula, historicoEad } = this.state
    const aluno = this.props.aluno 

    const dados = {
      historicos: historicos.list,
      historicosPorUnidade: historicosPorUnidade.list,
      historicosPorTipoAula: historicosPorTipoAula.list,
      historicoEad,
      filtroHistorico,
      cabecalhoTabelaHistorico,
      aluno
    }

    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/aluno/historico/imprimir`, dados, this.getToken())
      
      const dadosImprimir = {
        path: data
      }

      const resp = await axios.post(`${process.env.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })

      const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });

      this.setState({
        loadingImprimir: false
      })

      saveAs(pdfBlob, 'Historico-Aluno.pdf')

      this.setState({loadingTela: false})

    } catch ({ response }) {
      this.setState({loadingTela: false})
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }
  }

  async imprimirEad(){
    try {
      this.setState({loadingTela: true})

      const aluno = this.props.aluno 
  
      const dados = {
        aluno: aluno
      }

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/aluno/historicoEad/imprimir`, dados, this.getToken())
      
      const dadosImprimir = {
        path: data
      }

      const resp = await axios.post(`${process.env.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })

      const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });

      this.setState({
        loadingImprimir: false
      })

      saveAs(pdfBlob, 'Historico-Aluno-EAD.pdf')

      this.setState({loadingTela: false})

    } catch ({ response }) {
      this.setState({loadingTela: false})
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }
  }

  render() {
    const { filtroHistorico, historico, historicos, historicosPorUnidade, historicosPorTipoAula, historicoEad } = this.state
    return (
      <React.Fragment>
        {this.state.loading &&
          <React.Fragment>
            <div className="progressCircular">
              <CircularProgress />
            </div>
            <div className="progressText">
              <p>Carregando Histórico do Aluno...</p>
            </div>
          </React.Fragment>
        }
        {!this.state.loading &&
          <div>
            <Grid container spacing={1} direction="row" className="mg_top_20">
              <Grid item md={2} xs={12} sm={6}>
                <TextField type="date" className="input" label="Data Inicial*" variant="outlined" size="small" name="dataInicial" value={filtroHistorico.dataInicial} onChange={(e) => this.updateFiltroHistorico(e)} InputLabelProps={{ shrink: true }} />
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <TextField type="date" className="input" label="Data Final*" variant="outlined" size="small" name="dataFinal" value={filtroHistorico.dataFinal} onChange={(e) => this.updateFiltroHistorico(e)} InputLabelProps={{ shrink: true }} />
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <Button fullWidth variant="contained" color="primary" onClick={() => this.filtrarHistorico()} disabled={this.state.loadingTela}>
                  Filtrar
                </Button>
              </Grid>
              {this.state.permissoesImprimirHistorico.perm_visualizar &&
                <React.Fragment>
                  <Grid item md={2} xs={12} sm={6}>
                    <Button fullWidth variant="contained" color="secondary" onClick={() => this.imprimir()} disabled={this.state.loadingTela}>
                      Imprimir
                    </Button>
                  </Grid>
                  <Grid item md={2} xs={6} sm={4}>
                    <ColorYellowButton fullWidth variant="contained" onClick={() => this.imprimirEad()} disabled={this.state.loadingTela}>
                      Historico EAD
                    </ColorYellowButton>
                  </Grid>
                </React.Fragment>
              }
            </Grid>
            <div className="cardEndereco">
              <Grid container spacing={1} direction="row">
                <Grid item md={11} xs={10}>
                  <h4>Histórico de Aulas</h4>
                </Grid>
                {this.state.loadingTela &&
                 <Grid item md={1} xs={2}>
                  <CircularProgress />
                 </Grid>
                }
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={12} xs={12} id='historicoAluno'>
                  <TableHistorico 
                    headCell={this.state.cabecalhoTabelaHistorico} 
                    rows={historicos.list} 
                    acoes={this.state.acoesTabelaHistorico} 
                    editar={e => this.editar(e)} 
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row">
                <Grid item md={12} xs={12}>
                  <span className='margin-0'>Carga horária total: {historico.carga_horaria_total}</span>
                </Grid>
              </Grid>
            </div>
            <div className="cardEndereco">
              <Grid container spacing={1} direction="row">
                <Grid item md={12} xs={12}>
                  <h4>Histórico por unidade</h4>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={12} xs={12}>
                  <TableHistorico headCell={this.state.cabecalhoTabelaHistoricoPorUnidade} rows={historicosPorUnidade.list} acoes={this.state.acoesTabelaHistoricoPorUnidade} />
                </Grid>
              </Grid>
            </div>
            <div className="cardEndereco">
              <Grid container spacing={1} direction="row">
                <Grid item md={12} xs={12}>
                  <h4>Histórico por tipo de aula</h4>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={12} xs={12}>
                  <TableHistorico 
                    headCell={this.state.cabecalhoTabelaHistoricoPorTipoAula} 
                    rows={historicosPorTipoAula.list} 
                    acoes={this.state.acoesTabelaHistoricoPorTipoAula} 
                  />
                </Grid>
              </Grid>
            </div>
            <div className="cardEndereco">
              <Grid container spacing={1} direction="row">
                <Grid item md={12} xs={12}>
                  <h4>Histórico EAD</h4>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={12} xs={12}>
                  <TableHistorico 
                    headCell={this.state.cabecalhoTabelaHistoricoEad} 
                    rows={historicoEad} 
                    acoes={this.state.acoesTabelaHistoricoPorTipoAula} 
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        }
        <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        <ModalUpdateHistorico 
          open={this.state.modalUpdateHistorico} 
          handleClose={e => this.handleCloseModalUpdateHistorico(e)}
          historicoSelecionado={this.state.historicoSelecionado}
          updatePresenca={e => this.updatePresenca(e)}
          updateNotaAvaliacao={(e, hisaluno_id, avaliacao) => this.updateNotaAvaliacao(e, hisaluno_id, avaliacao)} 
          confirmar={e => this.confirmarUpdateHistorico(e)}/>
      </React.Fragment>
    )
  }
}